import router from '@/router';
import logout from '@/api/endpoints/authorisation/logout.js';

const initialState = ({ token: null });

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAuthorisationToken(state, token) {
      Object.assign(state, { token });
    },
  },
  actions: {
    async logout({ commit }) {
      await logout();
      commit('setAuthorisationToken', '');
      router.push({
        name: 'login',
      });
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    getToken: state => state.token,
  },
};
