import dayjs from '@/plugins/dayjs'

export const defaultRequestState = {
  pending: false,
  success: false,
  rejected: false,
  done: false,
  updatedAt: dayjs().format()
}

export const defaultState = {
  data: null,
  request: {
    ...defaultRequestState,
    createdAt: dayjs().format()
  },
  error: null
}

export const setPending = (state) => {
  state.request = {
    ...defaultRequestState,
    pending: true
  }
}

export const setSuccess = (state, data = []) => {
  state.data = data
  state.request = {
    ...defaultRequestState,
    success: true,
    done: true,
    updatedAt: dayjs().format()
  }
}

export const setRejected = (state, error) => {
  state.request = {
    ...defaultRequestState,
    rejected: true,
    done: true
  }
  state.error = error
}
