// WORKING WITH UTIL/STORE AND CONSTANTS IS AN EXPERIMENT. REFER TO
// https://documentready.atlassian.net/browse/KCI-143 FOR MORE DETAILS

import { profile } from '@/api/endpoints/profile';
import * as helpers from '@/application/helpers/storeRequest';
import ProfileModel from '@/application/models/profile';
import { defaultState, setPending, setRejected, setSuccess } from '@/application/util/storeRequest';
import {
  FETCH_PROFILE, FETCH_PROFILE_REJECTED, FETCH_PROFILE_SUCCESS, GET_PROFILE,
} from '@/store/modules/profile/actionTypes';

export default {
  namespaced: true,
  state: {
    ...defaultState,
    data: {
      name: '',
      email: '',
      isAdmin: false,
      dashboardId: null,
      startUrl: null,
      favoriteUrl: null,
    },
  },
  mutations: {
    [FETCH_PROFILE]: setPending,
    [FETCH_PROFILE_SUCCESS]: setSuccess,
    [FETCH_PROFILE_REJECTED]: setRejected,
  },
  actions: {
    /**
     * FETCH_PROFILE should only be called once. If there should be an
     * update profile kinda action, create one instead.
     */
    async [FETCH_PROFILE]({
      commit,
      getters,
    }) {
      const {
        isPending,
        isDone,
      } = getters;
      if (isDone || isPending) return;
      try {
        commit(FETCH_PROFILE);
        const { data: { data } } = await profile();
        const Profile = new ProfileModel().mapResponse(data);
        commit(FETCH_PROFILE_SUCCESS, Profile);
      } catch (error) {
        commit(FETCH_PROFILE_REJECTED, error);
      }
    },
  },
  getters: {
    ...helpers,
    [GET_PROFILE]: (state) => state.data,
    isAdmin: (state) => !!state?.data?.isAdmin,
    hasFavoriteUrl: (state) => !!state?.data?.favoriteUrl,
  },
};
