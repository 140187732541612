<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script lang="js">
export default {
    name: 'App',
};
</script>
<style lang="scss">

.v-data-table-header th{
  white-space: nowrap;
  .v-icon {
    margin-left: 4px;
  }
}

</style>
