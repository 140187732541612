export default {
  namespaced: true,
  state: {
    routeResourceParams:{}
  },
  mutations: {
    setRouteResourceParams(state, {
      routeName,
      params,
    }) {
      state.routeResourceParams[routeName] = params;
    },
  },
  getters: {
    getSelectedRouteResourceParams: (state) => (routeName) => {
      return state.routeResourceParams[routeName];
    },
  },
};
