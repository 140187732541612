import Vue from 'vue';
import { i18n, vuetify } from './plugins';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.prototype.$store = store;
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app');
