import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const locales = { nl: require.context('@/locales/nl', false, /[A-Za-z0-9-_,\s]+\.json$/i) };

const messages = Object.fromEntries(Object.keys(locales).map(locale => [
  locale, Object.fromEntries(locales[locale].keys().map(localeModule => {
      const moduleName = localeModule.match(/([A-Za-z0-9-_]+)\./i)[1];
      return [moduleName, locales[locale](localeModule)];
    },
  )),
]));

export default new VueI18n({
  locale: 'nl' || navigator.language.split('-')[0],
  fallbackLocale: 'nl',
  messages,
});
