export default {
  namespaced: true,
  state: {
    clientId: undefined,
    authToken: undefined,
    requestId: undefined,
  },
  mutations: {
    setOauthInformation(state, {
      clientId,
      authToken,
      requestId,
    }) {
      state.clientId = clientId;
      state.authToken = authToken;
      state.requestId = requestId;
    },
    clearOauthInformation(state) {
      state.clientId = undefined;
      state.authToken = undefined;
      state.requestId = undefined;
    },
  },
};
