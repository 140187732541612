import store from '@/store';
import { FETCH_PROFILE, GET_PROFILE } from '@/store/modules/profile/actionTypes.js';

export const authenticationGuard = async (to, from, next) => {
  await store.dispatch(`profile/${FETCH_PROFILE}`);
  store.getters['authorisation/isLoggedIn'] ? next() : next({ name: 'login' });
};

export const adminGuard = async (to, from, next) => {
  await store.dispatch(`profile/${FETCH_PROFILE}`);
  store.getters['profile/isAdmin'] ? next() : next({ name: '403' });
};

export const startpageGuard = async (to, from, next) => {
  await store.dispatch(`profile/${FETCH_PROFILE}`);
  const profile = store.getters[`profile/${GET_PROFILE}`];

  profile.startUrl
    ? window.location = profile.startUrl
    : next();
};
export const favoritePageGuard = async (to, from, next) => {
  await store.dispatch(`profile/${FETCH_PROFILE}`);
  const profile = store.getters[`profile/${GET_PROFILE}`];

  profile.favoriteUrl
    ? window.location = profile.favoriteUrl
    : next();
};
