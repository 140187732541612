import { put, get } from '@/api/implementation/app';

export const profile = () => {
  return get("profile");
}

export const updatePassword = (currentPassword, password, passwordConfirmation) => put('profile/password', {
  currentPassword,
  password,
  passwordConfirmation,
});
