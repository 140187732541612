// WORKING WITH UTIL/STORE AND CONSTANTS IS AN EXPERIMENT. REFER TO
// https://documentready.atlassian.net/browse/KCI-143 FOR MORE DETAILS

import { index } from '@/api/endpoints/profile/microservice'
import * as helpers from '@/application/helpers/storeRequest'
import { defaultState, setPending, setRejected, setSuccess } from '@/application/util/storeRequest'
import {
  FETCH_MICROSERVICES, FETCH_MICROSERVICES_REJECTED, FETCH_MICROSERVICES_SUCCESS, GET_MICROSERVICES
} from '@/store/modules/microservices/actionTypes'

export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    [FETCH_MICROSERVICES]: setPending,
    [FETCH_MICROSERVICES_SUCCESS]: setSuccess,
    [FETCH_MICROSERVICES_REJECTED]: setRejected
  },
  actions: {
    async [FETCH_MICROSERVICES]({ commit }) {
      try {
        commit(FETCH_MICROSERVICES)
        const { data: { data } } = await index()

        commit(FETCH_MICROSERVICES_SUCCESS, data)
      } catch (error) {
        commit(FETCH_MICROSERVICES_REJECTED, error)
      }
    }
  },
  getters: {
    ...helpers,
    [GET_MICROSERVICES]: (state) => state.data
  }
}
