/**
 * Use this where you also have used util/store. These functions will return current state of
 * the store of a component.
 */

export const isPending = (state) => state.request.pending;
export const isSuccessful = (state) => state.request.success;
export const isRejected = (state) => state.request.rejected;
export const isDone = (state) => state.request.done;
export const isClean = (state) => !state.request.pending && !state.request.success && !state.request.rejected && !state.request.done;
