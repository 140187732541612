export default {
  path: '',
  component: () => import('@/templates/Authorisation'),
  children: [
    {
      path: 'oauth',
      name: 'oauth',
      component: () => import('@/views/oauth'),
    },
  ],
};
