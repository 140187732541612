import Model from '@/application/models/model.js';

export default class Profile extends Model {
  isAdmin = false;
  name = '';
  email = '';
  dashboardId = null;
  startUrl = null;
  favoriteUrl = null;

  mapForRequest() {
    return {
      isAdmin: this.isAdmin,
      name: this.name,
      email: this.email,
    };
  }
}
